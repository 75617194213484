import { default as index0aRO2d8yJKMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/index.vue?macro=true";
import { default as indexu5bm5jbzZWMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/index.vue?macro=true";
import { default as klaviyoyZ2AoP7OBtMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/klaviyo.vue?macro=true";
import { default as integrationsA5T85N6iwNMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations.vue?macro=true";
import { default as mailchimpOnLIzaqcwiMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/mailchimp.vue?macro=true";
import { default as membersfnz2yCgTfaMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/members.vue?macro=true";
import { default as partnersugT9MkKKS8Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/partners.vue?macro=true";
import { default as prizesMZQquN7xDoMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/prizes.vue?macro=true";
import { default as settings0cB6DaqlYRMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/settings.vue?macro=true";
import { default as share8NcljYC2iZMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/share.vue?macro=true";
import { default as _91slug_93xC5U57BfF8Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/[slug].vue?macro=true";
import { default as index2Psk4b8nkmMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/index.vue?macro=true";
import { default as new2qEwGMlk9aMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/new.vue?macro=true";
import { default as _91slug_93lyLDgXeLzLMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/[slug].vue?macro=true";
import { default as index2rbxrwMCFqMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/index.vue?macro=true";
import { default as newI94a0jrXVIMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/new.vue?macro=true";
import { default as dashboardg0LoEWPDICMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard.vue?macro=true";
import { default as loginIWItxI5gmFMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/donor/login.vue?macro=true";
import { default as editorHa8vJ0FGSeMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/editor.vue?macro=true";
import { default as forgot_45passwordTyI3fDiPMuMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/forgot-password.vue?macro=true";
import { default as indexB8TuzdeIomMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/index.vue?macro=true";
import { default as _91slug_93rioX116q1QMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/invite/[organizationId]/[slug].vue?macro=true";
import { default as login85ATqQ5mVHMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/login.vue?macro=true";
import { default as indexSmhTzUgYijMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/mailchimp/redirect/index.vue?macro=true";
import { default as profileIOMbTil01UMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/profile.vue?macro=true";
import { default as rare_45impact_45testzoh8uepSjIMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/rare-impact-test.vue?macro=true";
import { default as rare_45impactGceEE36eo5Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/rare-impact.vue?macro=true";
import { default as reset_45password8C8gNhl3e3Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/reset-password.vue?macro=true";
import { default as signupN5ayRxnVAPMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/signup.vue?macro=true";
import { default as callback71Etxl6Qx5Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/stripe/callback.vue?macro=true";
import { default as checkoutfk3dlHvkRgMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/checkout.vue?macro=true";
import { default as giveawaysAKAsMcVca3Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/giveaways.vue?macro=true";
import { default as impact_45feedwyRSfnXsAqMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/impact-feed.vue?macro=true";
import { default as indexRcc3sfuwk6Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/index.vue?macro=true";
import { default as sweepstakeseKlgsqcns1Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/sweepstakes.vue?macro=true";
import { default as index50EU6bKMZfMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/welcome/index.vue?macro=true";
import { default as _91slug_93vxbnjzOUJlMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug].vue?macro=true";
import { default as testTe24S6sl6gMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/test.vue?macro=true";
export default [
  {
    name: dashboardg0LoEWPDICMeta?.name,
    path: "/dashboard",
    meta: dashboardg0LoEWPDICMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsA5T85N6iwNMeta?.name,
    path: "integrations",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-integrations",
    path: "",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-integrations-klaviyo",
    path: "klaviyo",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/klaviyo.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-mailchimp",
    path: "mailchimp",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/mailchimp.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-members",
    path: "members",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/members.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-partners",
    path: "partners",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/partners.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-prizes",
    path: "prizes",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/prizes.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "settings",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-share",
    path: "share",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/share.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tiers-slug",
    path: "tiers/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tiers",
    path: "tiers",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tiers-new",
    path: "tiers/new",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-updates-slug",
    path: "updates/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-updates",
    path: "updates",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-updates-new",
    path: "updates/new",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "donor-login",
    path: "/donor/login",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/donor/login.vue").then(m => m.default || m)
  },
  {
    name: "editor",
    path: "/editor",
    meta: editorHa8vJ0FGSeMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/editor.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-organizationId-slug",
    path: "/invite/:organizationId()/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/invite/[organizationId]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mailchimp-redirect",
    path: "/mailchimp/redirect",
    meta: indexSmhTzUgYijMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/mailchimp/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileIOMbTil01UMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "rare-impact-test",
    path: "/rare-impact-test",
    meta: rare_45impact_45testzoh8uepSjIMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/rare-impact-test.vue").then(m => m.default || m)
  },
  {
    name: "rare-impact",
    path: "/rare-impact",
    meta: rare_45impactGceEE36eo5Meta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/rare-impact.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "stripe-callback",
    path: "/stripe/callback",
    meta: callback71Etxl6Qx5Meta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/stripe/callback.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vxbnjzOUJlMeta?.name,
    path: "/subdomain/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "subdomain-slug-checkout",
    path: "checkout",
    meta: checkoutfk3dlHvkRgMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "subdomain-slug-giveaways",
    path: "giveaways",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/giveaways.vue").then(m => m.default || m)
  },
  {
    name: "subdomain-slug-impact-feed",
    path: "impact-feed",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/impact-feed.vue").then(m => m.default || m)
  },
  {
    name: "subdomain-slug",
    path: "",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "subdomain-slug-sweepstakes",
    path: "sweepstakes",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/sweepstakes.vue").then(m => m.default || m)
  },
  {
    name: "subdomain-slug-welcome",
    path: "welcome",
    meta: index50EU6bKMZfMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/welcome/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/test.vue").then(m => m.default || m)
  }
]